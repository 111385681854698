<template>
    <div>
        <Loader v-if="loading"></Loader>
        <div v-else>
            <showQuest v-if="isEditView && showContent" />
            <div v-if="isEditView" class="button-show-form-container">
                <CButton v-if="showContent" class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                    {{ backButton }}
                </CButton>
                <button v-if="!showForm && isAdmin" class="btn btn-show-form" @click="showEditForm">
                    Editar cuestionario
                </button>
            </div>
            <CCard v-if="showForm">
                <CCardHeader>
                    <strong>{{ formTitle }}</strong>
                </CCardHeader>
                <CCardBody>
                    <CForm>
                        <div class="quest-inputs">
                            <CInput id="title" description="Título del cuestionario" label="Título del cuestionario"
                                horizontal v-model="questData.title"
                                @input="validateInput('title', questData.title, 'Título del cuestionario')" />
                            <div v-if="errors['title']" class="text-danger-alert">
                                {{ errors['title'] }}
                            </div>

                            <CTextarea id="description" description="Descripción del cuestionario"
                                label="Descripción del cuestionario" horizontal v-model="questData.description"
                                @input="validateInput('description', questData.description, 'Descripción del cuestionario')" />

                            <div v-if="errors['description']" class="text-danger-alert">
                                {{ errors['description'] }}
                            </div>

                            <CInput id="periodicity" description="Periocidad del cuestionario"
                                label="Periocidad del cuestionario (Días)" horizontal v-model="questData.periodicity"
                                type="number" placeholder="0" min="0"
                                @input="validateInput('periodicity', questData.periodicity)" />

                            <div v-if="errors['periodicity']" class="text-danger-alert">
                                {{ errors['periodicity'] }}
                            </div>

                            <CRow>
                                <CCol class="col-sm-3 align-middle" style="vertical-align: auto;">
                                    <h6>Enfermedad</h6>
                                </CCol>
                                <CCol class="col-sm-9 select-column">
                                    <select v-model="questData.disease">
                                        <option v-for="(option, optionIndex) in diseases" :key="optionIndex"
                                            :value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </CCol>
                            </CRow>
                        </div>
                        <div class="questions-container">
                            <CCardHeader>
                                <strong>Preguntas y respuestas</strong>
                            </CCardHeader>
                            <div v-for="(pregunta, index) of auxQuestQuestions" :key="pregunta.id">
                                <template>
                                    <fieldset class="border p-2 questions-fieldset">
                                        <legend class="w-auto questions">
                                            Pregunta {{ index + 1 }}
                                        </legend>
                                        <CRow>
                                            <CCol sm="10">
                                                <CInput
                                                    :placeholder="'Añadir pregunta'"
                                                    class="question" v-model="auxQuestQuestions[index].body"
                                                    @input="validateInput(`question_${index}`, auxQuestQuestions[index].body)"
                                                    />
                                                    <div v-if="errors[`question_${index}`]" class="text-danger-alert">
                                                        {{ errors[`question_${index}`] }}
                                                    </div>
                                            </CCol>
                                            <CCol sm="2">
                                                <CButton v-if="isAdmin" class="question-btn" color="danger"
                                                    variant="outline" size="sm" @click="deleteQuestionInput(index)">
                                                    Eliminar pregunta
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                        <fieldset class="border p-2 ml-4">
                                            <legend class="w-auto answers">
                                                Respuestas
                                            </legend>
                                            <CRow v-for="(respuesta, j) of pregunta.answers" :key="j">
                                                <CCol lg="8" md="8" sm="12">
                                                    <CInput
                                                        :placeholder="'Añadir respuesta'"
                                                        class="answer" type="input"
                                                        v-model="auxQuestQuestions[index].answers[j].answer"
                                                        @input="validateInput(`answer_${pregunta.id}_${j}`, auxQuestQuestions[index].answers[j].answer)" />
                                                        <div v-if="errors[`answer_${pregunta.id}_${j}`]" class="text-danger-alert">
                                                            {{ errors[`answer_${pregunta.id}_${j}`] }}
                                                        </div>
                                                </CCol>
                                                <CCol lg="2" md="2" sm="12">
                                                    <CInput description="Peso, importancia de la respuesta" label="" horizontal v-model="auxQuestQuestions[index].answers[j].weight" type="number" placeholder="0" value="0" min="0" 
                                                    :disabled="!auxQuestQuestions[index].answers[j].answer"
                                                    @input="validateInput(`weight_${pregunta.id + '_' + j}`, auxQuestQuestions[index].answers[j].weight, 'Peso')" />
                                                    <div v-if="errors[`weight_${pregunta.id + '_' + j}`]" class="text-danger-alert">
                                                            {{ errors[`weight_${pregunta.id + '_' + j}`] }}
                                                        </div>
                                                </CCol>
                                                <CCol lg="2" md="2" sm="12">
                                                    <CButton v-if="isAdmin" color="danger" variant="outline" size="sm"
                                                        @click="deleteAnswerInput(pregunta.id, j)">
                                                        Eliminar respuesta
                                                    </CButton>
                                                </CCol>
                                            </CRow>

                                            <CButton color="info" square size="sm"
                                                @click="addNewAnswerTextInput(pregunta.id)" class="add-answer"
                                                style="margin-right: 10px;">
                                                Añadir respuesta de texto
                                            </CButton>
                                        </fieldset>

                                    </fieldset>
                                </template>
                            </div>
                            <div class="d-flex justify-content-center container-buttons" style="margin-top: 20px;">
                                <CButton v-if="isAdmin" color="primary" square size="sm" @click="addNewQuestionInput()">
                                    Añadir una pregunta
                                </CButton>
                            </div>
                        </div>
                        <div class="categories-container">
                            <CCardHeader>
                                <strong>Categorías</strong>
                            </CCardHeader>
                            <div v-for="(category, categoryIndex) of auxCategories" :key="categoryIndex">
                                <template>
                                    <fieldset class="border p-2 categories-fieldset">
                                        <legend class="w-auto questions">
                                            Categoría {{ categoryIndex + 1 }}
                                        </legend>
                                        <CRow>
                                            <CCol class="categories-select-container" lg="5" md="12" sm="12">
                                                <CCol class="col-sm-3 col-md-3">
                                                    <h6>Categoría</h6>
                                                </CCol>
                                                <CCol class="col-sm-9 col-md-9">
                                                    <select v-model="auxCategories[categoryIndex].category">
                                                    <option v-for="(option, optionIndex) in categories" :key="optionIndex"
                                                        :value="option.id">
                                                        {{ option.name }}
                                                    </option>
                                                    </select>
                                                </CCol>
                                            </CCol>
                                            <CCol lg="3" md="6" sm="12" style="margin-top: 10px;">
                                                <CInput v-model="auxCategories[categoryIndex].value_from" type="number"
                                                    min="0" label="Valor mínimo" horizontal
                                                    @input="validateInput(`value_from_${categoryIndex}`, auxCategories[categoryIndex].value_from)" />
                                                <div v-if="errors[`value_from_${categoryIndex}`]" class="text-danger-alert">
                                                    {{ errors[`value_from_${categoryIndex}`] }}
                                                </div>
                                            </CCol>
                                            <CCol lg="3" md="6" sm="12" style="margin-top: 10px;">
                                                <CInput v-model="auxCategories[categoryIndex].value_to" type="number"
                                                    min="0" label="Valor máximo" horizontal
                                                    @input="validateInput(`value_to_${categoryIndex}`, auxCategories[categoryIndex].value_to)" />
                                                <div v-if="errors[`value_to_${categoryIndex}`]" class="text-danger-alert">
                                                    {{ errors[`value_to_${categoryIndex}`] }}
                                                </div>
                                            </CCol>
                                            <CCol lg="1" md="12" sm="12" class="button-delete-container">
                                                <CButton v-if="isAdmin" color="danger" variant="outline" size="sm"
                                                    @click="deleteCategory(categoryIndex)">
                                                    Eliminar
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </fieldset>
                                </template>
                            </div>
                            <div v-if="categoriesMessage !== ''" class="alert alert-danger category-error-alert" role="alert">
                                {{ categoriesMessage }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-center container-buttons" style="margin-top: 20px;">
                            <CButton v-if="isAdmin" class="btn-add-category" square size="sm" @click="addNewCategory()">
                                Añadir una categoría
                            </CButton>
                        </div>
                        <div class="total-sum-container">
                            <CCardHeader>
                                <strong>Suma total mínima y máxima de pesos</strong>
                            </CCardHeader>
                              <template>
                                    <fieldset class="border p-2">
                                        <CRow>
                                            <CCol lg="6" md="6" sm="12">
                                                <span><b>Suma total mínima de peso: </b>{{ totalMinWeight }}</span>
                                            </CCol>
                                            <CCol lg="6" md="6" sm="12">
                                                <span><b>Suma total máxima de peso: </b>{{ totalMaxWeight }}</span>
                                            </CCol>
                                        </CRow>
                                    </fieldset>
                              </template>
                        </div>
                    </CForm>
                    <div class="d-flex justify-content-center" style="margin-top:20px">
                        <CButton v-if="!isEditView" class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                            {{ backButton }}
                        </CButton>
                        <CButton v-if="isEditView" class="btn btn-danger" style="margin-right:1em;"
                            @click="restoreContent()">
                            Cancelar
                        </CButton>
                        <CButton v-if="isAdmin" class="btn btn-success" @click="editQuest()">
                            {{ textButton }}
                        </CButton>
                    </div>
                </CCardBody>
                <CModal class="c-modal" id="modalCreate"
                    :title="(isEditView) ? 'Editar cuestionario' : 'Crear cuestionario'" color="principal"
                    :show.sync="createModal" :closeOnBackdrop="false">
                    <p class="text-muted">
                        ¿Está seguro de que desea {{ (isEditView) ? 'editar' : 'crear' }} este cuestionario?
                    </p>
                    <div slot="footer">
                        <CButton color="secondary" style="margin-right: 1em;" @click="createModal = false">
                            Cerrar
                        </CButton>
                        <CButton color="success" @click="addQuest()">
                            Aceptar
                        </CButton>
                    </div>
                </CModal>
            </CCard>
        </div>
    </div>
</template>
  
<script>
import { getQuestDataById, createNewQuest, updateQuest, getQuestsData } from '@/services/quests';
import { getDiseasesData } from '@/services/diseases';
import { getCategoriesData } from "@/services/categories";
import { CFormSwitch } from '@coreui/vue'
import Loader from '@/components/Loader.vue';
import ShowQuest from './showQuest.vue';

export default {
    name: "EditarCuestionario",
    components: {CFormSwitch, Loader, ShowQuest},
    data() {
        return {
            id: null,
            quests: [],
            title: 'Editar cuestionario',
            formTitle: "Crear cuestionario",
            backButton: 'Volver',
            textButton: "Crear",
            categoriesMessage: "",
            errors: {},
            showForm: false,
            showAnswers: false,
            loading: true,
            showContent: true,
            showQuestions: false,
            questData: {
                title: "",
                description: "",
                periodicity: 0,
                disease: "",
                category: 0
            },
            diseases: [],
            categories: [],
            auxCategories: [],
            auxQuestQuestions: [],
            auxID: -1,
            auxIDAnswer: -1,
            auxCategoryId: -1,
            isEditView: false,
            createModal: false,
            newQuest: {},
            contAlertParams: 0,
            totalMinWeight: 0,
            totalMaxWeight: 0,
            answerFieldEnabled: false,
            isAdmin: this.$store.state.isAdmin
        };
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id != undefined) {
            this.isEditView = true;
            this.textButton = "Editar";
        } else {
            this.isEditView = false;
        }
        this.receiveData();
    },
    methods: {
        receiveData() {
            this.getQuests();
            this.getDiseases();
            if (!this.id){
                this.showForm = true;
                this.loading = false;
                return;
            } 
            this.formTitle = "Editar cuestionario";
            this.textButton = "Aceptar";
            this.getQuest();
            this.getCategories();
        },
        /**
         * Método para borrar los mensajes de error por posición
        */
        deleteErrorMessages(prefix) {
            Object.keys(this.errors).forEach((key) => {
                if (key.startsWith(prefix)) {
                    this.errors[key] = "";
                }
            });
        },
        /**
         * Método para borrar los mensajes por prefijos (todos)
        */
        deleteErrorsByPrefixes(prefixes) {
            for (const key in this.errors) {
                if (prefixes.some(prefix => key.startsWith(prefix))) {
                    delete this.errors[key];
                }
            }
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
         * Función en la que se obtienen los cuestionarios y que también
         * llama el metodo receiveData para cargarlos.
         * 
         * @returns {object[]} Los cuestionarios obtenidos.
         */
         getQuests() {
            getQuestsData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
                .then((result) => {
                    if (!this.id){
                        this.quests = result
                    }else{
                        this.quests = result.filter(quest => quest.id !== Number(this.id));
                    }
            });
        },
        /**
         * Método para setear los mensajes los alerts de error al hacer petición con el formulario
        */
        setAlertError(message){
            window.scrollTo({top: 0,behavior: "smooth"});
            this.showAlertError(message);
        },
        /**
         * Método para setear el valor a 0 del input de peso, en caso de que la respuesta esté vacía
        */
        handleEmptyAnswerInputValue(key, value) {
            const parts = key.split('_');
            const preguntaId = parts[1];
            const answerIndex = parts[2];

            const newValue = value.trim();

            if (newValue === '') {
                this.auxQuestQuestions.forEach(question => {
                    if (question.id == preguntaId) {
                        question.answers[answerIndex].weight = 0;
                    }
                });
            }
        },
        validateInput(key, value, name) {
            if(key === 'title' || key === 'description' || key.startsWith('question_') || key.startsWith('answer_')){
                this.textValidation(key, value, name);
                if (key.startsWith('answer_')) {
                    this.handleEmptyAnswerInputValue(key, value);
                }
            }else if (key === 'periodicity' || key.startsWith('value_from') || key.startsWith('value_to') || key.startsWith('weight')) {
                this.numberValidation(key, value);
            }else {
                this.errors[key] = '';
            }
        },
        /**
         * Método para validar los inputs donde se introduce texto.
        */
        textValidation(key, value){
            const specialCharsPattern = /^[a-zA-Z0-9,\.!¡¿?\(\)áéíóúÁÉÍÓÚüÜñÑ\s]+$/;
            if (value === '') {
                this.errors[key] = 'Este campo es obligatorio';
            }else if ((key === 'title' && value.length > 100) || (key === 'description' && value.length > 255) || (key.startsWith('question_') && value.length > 255) || (key.startsWith('answer_') && value.length > 255)) {
                this.errors[key] = `El número de caracteres de este campo debe ser menor o igual a ${(key === 'title') ? 100 : 255}.`;
            }else if ((key === 'title' || key === 'description' || key.startsWith('question_') || key.startsWith('answer_')) && !specialCharsPattern.test(value)) {
                this.errors[key] = 'Este campo solo acepta comas, puntos y los siguientes caracteres especiales: ?, ¿, !, ¡, ()';
            }else{
                this.errors[key] = '';
            }
            if(key.startsWith('answer_')){
              this.updateLimitsMinAndMax(key, value);
            }
        },
        /**
         * Método para validar los inputs donde se introducen números.
        */
        numberValidation(key, value){
            if(value === ""){
                this.errors[key] = 'Este campo es obligatorio';
            }else if(value > 2147483647){
                this.errors[key] = 'Este valor debe ser menor o como máximo igual a 2147483647.';
            }else if(value.toString().includes('-') || value.toString().includes('.') || value.toString().includes(',')){
                this.errors[key] = 'Este valor solo puede ser un número entero positivo.';
            }else if(key.startsWith('weight')){
                this.updateLimitsMinAndMax(key, value);
                this.categoriesMessage = this.checkLimits(key);
            }else if(key.startsWith('value_to') || key.startsWith('value_from')) {
                this.checkCategoryLimits(key, value);
            }else{
                this.errors[key] = '';
            }
        },
        /**
         * Método para obtener la suma del campo Peso, importancia de la respuesta
        */
        updateLimitsMinAndMax(key, value) {
            let maxSum = 0;
            let minSum = 0;
            this.auxQuestQuestions.forEach(question => {
                let maxWeight = 0;
                let minWeight = Infinity;

                question.answers.forEach(answer => {
                    const weight = parseInt(answer.weight);

                    // Actualizar suma total del peso máximo
                    if (weight > maxWeight) {
                        maxWeight = weight;
                    }

                    // Actualizar suma total del peso mínimo
                    if (weight < minWeight) {
                        minWeight = weight;
                    }

                    if(minWeight === Infinity){
                        minWeight = 0;
                    }
                    
                });

                maxSum += maxWeight;
                minSum += minWeight;
            });

            this.totalMinWeight = (minSum === Infinity ? 0 : minSum);
            this.totalMaxWeight = maxSum;

            this.categoriesMessage = this.checkLimits(key);
            if(value !== "") this.errors[key] = '';
        },
        checkLimits(key) {
            const sortedCategories = this.auxCategories.slice().sort((a, b) => a.value_from - b.value_from);
            const categoriesMap = new Map();

            // Asignar números de categoría basados en el orden original
            for (let i = 0; i < sortedCategories.length; i++) {
                const categoria = sortedCategories[i];
                const index = this.auxCategories.findIndex(c => c === categoria);
                categoriesMap.set(categoria, index + 1);
            }

            for (let i = 0; i < sortedCategories.length; i++) {
                const currentCategoria = sortedCategories[i];

                for (let j = i + 1; j < sortedCategories.length; j++) {
                    const nextCategoria = sortedCategories[j];

                    // Verificar solapamiento
                    if (Number(currentCategoria.value_to) >= Number(nextCategoria.value_from) && Number(currentCategoria.value_from) <= Number(nextCategoria.value_to)) {
                        return `Error: Las categorías ${categoriesMap.get(currentCategoria)} y ${categoriesMap.get(nextCategoria)} se solapan.`;
                    }
                }

                // Verificar puntuaciones inexistentes
                if (i < sortedCategories.length - 1 && Number(currentCategoria.value_to) != Number(sortedCategories[i + 1].value_from - 1)) {
                    return `Error: Las categorías ${categoriesMap.get(currentCategoria)} y ${categoriesMap.get(sortedCategories[i + 1])} no cubren puntuaciones consecutivas.`;
                }
            }

            // Verificar que el valor mínimo no supere al máximo
            if (key !== undefined && key.startsWith('value_to')) {
                for (let i = 0; i < this.auxCategories.length; i++) {
                    let valueFrom = this.auxCategories[i].value_from;
                    let valueTo = this.auxCategories[i].value_to;
                    if (Number(valueFrom) > Number(valueTo)) {
                        return `Error: El valor mínimo no puede superar al valor máximo en la categoría ${categoriesMap.get(this.auxCategories[i])}: ${valueFrom} - ${valueTo}`;
                    }
                }
            }
            return "";
        },
        /**
         * Método genérico de validación de limites
        */
        validateLimits(key, limitValue, minValue, maxValue, errorMessage1, errorMessage2) {
            if (limitValue < minValue) {
                this.errors[key] = errorMessage1;
            } else if (limitValue > maxValue) {
                this.errors[key] = errorMessage2;
            } else {
                this.errors[key] = '';
            }
        },
        /**
         * Método para validar los valores mínimos y máximos
        */
        checkCategoryLimits(key, value) {
            if (key.startsWith('value_from')) {
                this.validateLimits(key, parseInt(value), this.totalMinWeight, this.totalMaxWeight, 'El valor mínimo no puede ser menor a la (Suma total mínima de peso)', 'El valor mínimo no puede ser mayor a la (Suma total máxima peso)');
            } else if (key.startsWith('value_to')) {
                this.validateLimits(key, parseInt(value), this.totalMinWeight, this.totalMaxWeight, 'El valor máximo no puede ser menor a la (Suma total mínima de peso)', 'El valor máximo no puede ser mayor a la (Suma total máxima peso)');
            } else {
                this.errors[key] = '';
            }
            this.categoriesMessage = this.checkLimits(key);
        },
        /**
         * Método para verificar que hay pregutnas y respuestas antes de pulsar 'Añadir una categoría'
        */
        checkCategoryButton(){
            if(this.auxQuestQuestions.length === 0){
                this.showAnswers = false;
            }else{
                for (let i = 0; i < this.auxQuestQuestions.length; i++) {
                    const quest = this.auxQuestQuestions[i];
                    this.showAnswers = quest.answers.length > 0;
                }
            }
        },
        /**
         * Método para poder obtener la enfermedad y preseleccionarla en el Select
        */
        questDiseaseValue(diseaseId) {
            const disease = this.diseases.find((i) => i.id == diseaseId);
            this.questData.disease = disease.id;
        },
        questCategoryValue(categoryId, categoryIndex) {
            const category = this.categories.find((i) => i.id == categoryId);
            this.auxCategories[categoryIndex].category = category.id;
        },
        /**
         * Función para obtener los datos del cuestionario
         */
        getQuest() {
            getQuestDataById(this.id)
            .catch((error) => {
                if (error.response && error.response.status === 404){
                    this.$router.push('/404');
                    return
                }
                this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; 
            })
            .then((result) => {
                this.questData = result;
                if(result.disease !== null) this.questDiseaseValue(result.disease.id);
                this.auxQuestQuestions = this.questData.questions
                this.auxCategories = this.questData.categories.sort((a, b) => a.id - b.id);
                this.auxCategories.forEach((category, index) => {
                    this.questCategoryValue(category.id, index);
                });
                this.checkCategoryButton();
                this.loading = false;
                this.updateLimitsMinAndMax();
            })
        },
         /**
         * Función en la que se obtienen las categorías
         *
         * @returns {object[]} Las categorías obtenidas.
         */
        getCategories() {
            getCategoriesData()
                .catch((error) => {
                    this.$store.state.errorAlert = {
                        status: true,
                        msg: error.toString(),
                    };
                    this.loading = false;
                })
                .then((result) => {
                    this.categories = result;
                    this.loading = false;
                });
        },
        /**
         * Función en la que se obtienen las enfermedades
         * 
         * @returns {object[]} Las enfermedades obtenidas.
         */
        getDiseases() {
            getDiseasesData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((result) => {
                this.diseases = result
                this.diseases.forEach((disease) => {
                    disease.id = disease.id
                    disease.name = disease.name
                })
                this.loading = false;
            });
        },
        /**
        * Función para añadir nueva respuesta de tipo texto al cuestionario.
        */
        addNewAnswerTextInput(preguntaId) {
            this.auxIDAnswer = this.auxIDAnswer - 1
            let newAnswer = {
                id: this.auxIDAnswer,
                answer: "",
                weight: 0
            }
            this.auxQuestQuestions.forEach(element => {
                if (element.id == preguntaId) {
                    element.type = "text"
                    element.answers.push(newAnswer)
                    this.answerFieldEnabled = true; 
                }
            });
            this.checkCategoryButton();
        },
        /**
         * Función para poder eliminar la respuesta del cuestionario.
         */
        deleteAnswerInput(preguntaId, position) {
            this.auxQuestQuestions.forEach(question => {
                if (question.id == preguntaId) {
                    question.answers.splice(position, 1)
                }
                if (question.answers.length == 0) {
                    question.type = ""
                    this.answerFieldEnabled = false;
                }
                if(this.auxQuestQuestions.length === 0){
                    this.auxCategories = []
                }
            });
            this.updateLimitsMinAndMax(`answer_${preguntaId}_${position}`);
            this.deleteErrorMessages(`weight_${preguntaId}_${position}`);
            this.categoriesMessage = this.checkLimits();
            this.checkCategoryButton();
        },
        /**
         * Función para añadir nueva pregunta  al cuestionario.
         */
        addNewQuestionInput() {
            this.auxID = this.auxID - 1
            let newQuestion = {
                id: this.auxID,
                body: "",
                answers: [],
                type: ""
            }
            this.auxQuestQuestions.push(newQuestion)
        },
        /**
         * Función para eliminar pregunta del cuestionario.
         */
        deleteQuestionInput(position) {
            this.auxQuestQuestions.splice(position, 1)
            if(this.auxQuestQuestions.length === 0){
                this.auxCategories = []
                this.deleteErrorsByPrefixes(['value_from_', 'value_to_', 'question_', 'answer_']);
            }
            this.categoriesMessage = this.checkLimits();
            this.updateLimitsMinAndMax();
            this.checkCategoryButton();
        },
         /**
         * Función para añadir nueva categoría  al cuestionario.
         */
         addNewCategory() {
            if(this.showAnswers){
                const newCategoryId = this.auxCategories.length > 0 ? this.auxCategories[this.auxCategories.length - 1].id + 1 : 1;
                let newCategory = { id: newCategoryId, name: "",  value_from: 0, value_to: 0 }
                this.auxCategories.push(newCategory);
                this.getCategories();
            }else{
                this.setAlertError('Antes de añadir una categoría debes añadir como mínimo una pregunta y una respuesta.')
            }
        },
        /**
         * Función para eliminar categoría del cuestionario.
         */
         deleteCategory(position) {
            this.auxCategories.splice(position, 1);
            this.categoriesMessage = this.checkLimits();
            this.deleteErrorMessages(`value_from_${position}`);
            this.deleteErrorMessages(`value_to_${position}`);
        },
        /**
         * Función para poder editar el cuestionario.
         */
        editQuest() {
            const maxLengthTitle = 100;
            const maxLengthDescription = 255;
            const specialCharsPattern = /^[a-zA-Z0-9,\.!¡¿?\(\)áéíóúÁÉÍÓÚüÜñÑ\s]+$/;
            const { title, description, periodicity, disease } = this.questData;

            if (title === "" || title === null) {
                this.setAlertError('El campo Título del cuestionario esta vacío')
                return;
            }
            if (description === "" || description === null) {
                this.setAlertError('El campo Descripción del cuestionario esta vacío')
                return;
            }
            if (title.length > maxLengthTitle) {
                this.setAlertError(`El número de caracteres del Título del cuestionario debe ser menor o igual a ${maxLengthTitle}`)
                return;
            }
            if (description.length > maxLengthDescription) {
                this.setAlertError(`El número de caracteres de la Descripción del cuestionario debe ser menor o igual a ${maxLengthDescription}`);
                return;
            }
            if (!specialCharsPattern.test(title)) {
                this.setAlertError("El título del cuestionario solo acepta comas, puntos y los siguientes caracteres especiales: ?, ¿, !, ¡, ()");
            }
            if (!specialCharsPattern.test(description)) {
                this.setAlertError("La descripción del cuestionario solo acepta comas, puntos y los siguientes caracteres especiales: ?, ¿, !, ¡, ()");
                return;
            }
            if (periodicity < 0 || periodicity.toString().includes('-') || periodicity.toString().includes('.') || periodicity.toString().includes(',')) {
                this.setAlertError("La periocidad del cuestionario solo puede ser un número entero positivo");
            }
            if(periodicity > 2147483647){
                this.setAlertError("El valor de la periocidad debe ser menor o como máximo igual a 2147483647");
                return;
            }
            if(this.auxCategories.length === 0){
                this.setAlertError(`Debes añadir al menos una categoría para poder ${this.isEditView ? 'editar': 'crear'} el cuestionario.`);
                return;
            }

            for (let i = 0; i < this.auxQuestQuestions.length; i++) {
                let question = this.auxQuestQuestions[i].body;
                let answers = this.auxQuestQuestions[i].answers
                if(question === ""){
                    this.setAlertError(`La pregunta ${i + 1} no puede estar vacía.`)
                    return;
                }
                if(answers.length === 0){
                    this.setAlertError(`La pregunta ${i + 1} debe tener una respuesta.`)
                    return;
                }
                for (let j = 0; j < this.auxQuestQuestions[i].answers.length; j++) {
                    let answer = this.auxQuestQuestions[i].answers[j].answer;
                    if(answer === ""){
                        this.setAlertError(`La respuesta ${j + 1} de la pregunta ${i + 1} no puede estar vacía.`)
                        return;
                    }
                }
            }

            let firstValueFrom = null;
            let lastValueTo = null;

            const sortedCategories = this.auxCategories.slice().sort((a, b) => a.value_from - b.value_from);

            for (let i = 0; i <  this.auxCategories.length; i++) {
                let category = this.auxCategories[i].category;
                let valueFrom = this.auxCategories[i].value_from;
                let valueTo = this.auxCategories[i].value_to;

                if (category === undefined){
                    this.setAlertError(`Selecciona una categoría en la sección: Categoría ${i + 1}`);
                    return;
                }
                if(valueFrom === ""){
                    this.setAlertError(`Debes asignar el valor mínimo en la sección: Categoría ${i + 1}`);
                    return;
                }
                if(valueTo === ""){
                    this.setAlertError(`Debes asignar el valor máximo en la sección: Categoría ${i + 1}`);
                    return;
                }
                if(Number(valueFrom) > Number(valueTo)){
                    this.setAlertError(`El valor mínimo no puede superar al valor máximo en la sección: Categoría ${i + 1}`);
                    return;
                }
                if (valueFrom.toString().includes('-') || valueFrom.toString().includes('.') || valueFrom.toString().includes(',')) {
                    this.setAlertError(`El valor mínimo solo puede ser un número entero positivo: Categoría ${i + 1}`);
                }
                if(valueFrom > 2147483647 || valueTo > 2147483647){
                    this.setAlertError(`El valor debe ser menor o como máximo igual a 2147483647: Categoría ${i + 1}`);
                    return;
                }
            }

            for (let i = 0; i < sortedCategories.length; i++) {
                let valueFrom = sortedCategories[i].value_from;

                if (firstValueFrom === null) {
                    firstValueFrom = valueFrom;
                }

                if (sortedCategories.length > 0) {
                    lastValueTo = sortedCategories[sortedCategories.length - 1].value_to;
                }

                if(this.totalMaxWeight > Number(lastValueTo) || Number(firstValueFrom) > this.totalMinWeight){
                    this.setAlertError(`Se debe cubrir todo el rango de la suma total mínima y máxima de peso`);
                    return;
                }
            }

            const existingQuest = this.quests.find(quest => {
                return quest.title.toLowerCase() === title.toLowerCase()
            });
            
            if (existingQuest) {
                this.setAlertError(`El nombre "${title}" del cuestionario ya existe en los cuestionarios.`);
                return;
            }

            const hasErrors = Object.values(this.errors).some(error => error !== '');

            if (hasErrors || this.categoriesMessage !== "") {
                this.setAlertError('Corrige los errores antes de continuar.');
                return;
            }
            
            this.createModal = true
        },
        /**
        * Método para mostrar errores de respuesta del Backend
        */
        showingRequestError(error){
            if(error.response.data.message === "Category value not valid"){
                this.setAlertError('Las categorías añadidas no son válidas, revisa los valores mínimos y máximos.')
                return;
            }
            this.$store.state.errorAlert = { status: true, msg: error.toString() } 
        },
        /**
        * Función para crear o editar un cuestionario.
        */
        addQuest() {
            let dataToSend = {
                title: this.questData.title,
                description: this.questData.description,
                periodicity: this.questData.periodicity,
                questions: this.auxQuestQuestions,
                categories: [],
            }

            if (this.questData.disease) {
                dataToSend.id_disease = this.questData.disease;
            }
            
            let auxQuestions = JSON.parse(JSON.stringify(dataToSend.questions))

            dataToSend.questions.forEach(question => {
                question.answers.forEach(answer => {
                    answer.weight = Number(answer.weight);
                });
            });
            
            for (let i = auxQuestions.length - 1; i >= 0; i--) {
                if (auxQuestions[i].body == "") {
                    dataToSend.questions.splice(i, 1)
                }
            }
            for (let i = 0; i < this.auxCategories.length; i++) {
                dataToSend.categories.push({
                    id: this.auxCategories[i].category,
                    value_from: Number(this.auxCategories[i].value_from),
                    value_to: Number(this.auxCategories[i].value_to)
                });
            }

            this.newQuest = JSON.parse(JSON.stringify(dataToSend))

            if (!this.isEditView) {
                createNewQuest(this.newQuest)
                .then(() => {
                    this.$router.push("/quest");
                })
                .catch((error) => {
                    this.showingRequestError(error);
                })
            }

            if (this.isEditView) {
                updateQuest(this.newQuest, this.id)
                .then(() => {
                    this.$router.push("/quest");
                })
                .catch((error) => {
                    this.showingRequestError(error);
                })
            }
            this.createModal = false
        },
        /**
        * Función para mostrar el alert durante 10 segundos.
        */
        showFailureParameters() {
            this.contAlertParams = 5;
        },
        /**
         * Función para mostrar o ocultar la información del cuestionario
         */
        toggleContentInfo(showContent, showForm){
            this.showContent = showContent;
            this.showForm = showForm;
            window.scrollTo({top: 0,behavior: "smooth"});
        },
         /**
         * Función para mostrar el formulario para editar el cuestionario
         */
         showEditForm(){
            this.toggleContentInfo(false, true)
        },
        /**
         * Función para restaurar el contenido
         */
         restoreContent(){
            this.toggleContentInfo(true, false)
        },
        /**
         * Función para volver atrás
         */
        back() {
            this.createModal = false
            this.newQuest = {}
            this.$router.push("/quest");
        }
    },
};
</script>
<style scoped>
.total-sum-container header{
    font-size: 17px !important;
}
.questions-fieldset, .categories-fieldset{
    margin: 10px 0;
    border: 2px solid #d8dbe0 !important;
}
.questions-fieldset legend, .categories-fieldset legend{
    font-size: 1.3rem;
}
.questions-container header, .categories-container header{
    font-size: 22px;
}
.categories-container{
    margin-top: 20px;
}
.btn-add-category{
    background-color: #4c86d9;
    color: #fff;
}
.category-error-alert{
    margin-top: 10px;
}
.total-sum-container{
    margin-top: 20px;
}
.total-sum-container span{
  font-size: 15px;
  padding-left: 10px;
}
.categories-select-container{
    display: flex;
    padding-top: 12px;
}
@media (max-width: 990px) {
.categories-select-container{
  padding: 10px 0;
} 
}
@media (max-width: 639px) {
    .total-sum-container span{
        font-size: 14px;
    } 
    .categories-select-container{
        display: block;
    } 
}
</style>