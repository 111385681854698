import config from "@/config";

import { get, del, post, patch } from "@/requests"

export async function getDiseasesData() {
    return get(`${config.BACK_IP}/diseases`)
}
export async function getDiseaseData(id) {
    return get(`${config.BACK_IP}/disease/${id}`)
}

export async function createNewDisease(newDisease) {
    return post(`${config.BACK_IP}/disease`, newDisease)
}

export async function updateDisease(disease, id) {
    return patch(`${config.BACK_IP}/disease/${id}`, disease)
}

export function deleteDisease(id) {
    return del(`${config.BACK_IP}/disease/${id}`)
}